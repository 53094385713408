/*
 * *****************************************************************************
 * Customer types that define one type of inventory report; These
 * 'customer types' are more specific than just BRW, CONBRW, etc. See
 * CODE_COMMENTS_213
 * *****************************************************************************
*/

// What's the difference between a regular customer type and a customer type for
// report inventory? See CODE_COMMENTS_213
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY'

export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_BRW_PUBS_ONLY'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_WAREHOUSE'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_MAINTENANCE_FACILITY'
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DISTRIBUTOR_NO_LOGIN_REQUIRED' // CODE_COMMENTS_267
export const CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED = 'CUSTOMER_TYPE_FOR_REPORT_CONSTELLATION_DISTRIBUTOR_NO_LOGIN_REQUIRED' // CODE_COMMENTS_267
// This type of customer will never have a configObj assigned to it, but this
// flag is still useful for signaling that the Report Inventory option in the
// Dashboard should be hidden/unrendered.
export const CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY = 'CUSTOMER_TYPE_FOR_REPORT_INVENTORY_DOES_NOT_REPORT_INVENTORY'


/*
 * *****************************************************************************
 * Configuration objects that define one type of inventory report: see
 * CODE_COMMENTS_213
 * *****************************************************************************
*/
export const REPORT_INVENTORY_CONFIG_OBJECT = 'REPORT_INVENTORY_CONFIG_OBJECT'

export const REPORT_INVENTORY_CONFIG_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS = 'REPORT_INVENTORY_CONFIG_BRW_WITH_DEFAULT_BRW_CONTRACT_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS'
export const REPORT_INVENTORY_CONFIG_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'REPORT_INVENTORY_CONFIG_BRW_WITH_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'
export const REPORT_INVENTORY_CONFIG_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'REPORT_INVENTORY_CONFIG_BRW_WITH_NO_DEFAULT_BRW_CONTRACT_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'

export const REPORT_INVENTORY_CONFIG_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS = 'REPORT_INVENTORY_CONFIG_CONBRW_NOT_REPORTING_ON_ANY_CONBRW_CONTRACTS'
export const REPORT_INVENTORY_CONFIG_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS = 'REPORT_INVENTORY_CONFIG_CONBRW_REPORTING_ON_ONE_OR_MORE_CONBRW_CONTRACTS'

export const REPORT_INVENTORY_CONFIG_DISTRIBUTOR = 'REPORT_INVENTORY_CONFIG_DISTRIBUTOR'


/*
 * *****************************************************************************
 * INVENTORY_REPORT_OBJS: CODE_COMMENTS_220, CODE_COMMENTS_215
 * *****************************************************************************
*/

export const INVENTORY_REPORT_OBJS = 'INVENTORY_REPORT_OBJS'
// Brewers only
export const INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ = 'INVENTORY_REPORT_OBJS_DEFAULT_BREWING_CONTRACT_REPORT_OBJ'
// Contract Brewers only
export const INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ = 'INVENTORY_REPORT_OBJS_CBMST_REPORT_OBJ'
// Only set for Distributors
export const INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ = 'INVENTORY_REPORT_OBJS_DISTRIBUTOR_REPORT_OBJ'
export const INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS = 'INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS' // CODE_COMMENTS_267
export const INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS = 'INVENTORY_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS' // CODE_COMMENTS_267

export const CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS = 'CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_OBJS' // CODE_COMMENTS_267
export const CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS = 'CONSTELLATION_REPORT_OBJS_DISTRIBUTOR_NO_LOGIN_REPORT_INDIVIDUAL_OBJS' // CODE_COMMENTS_267

export const INVENTORY_REPORT_OBJS_PUBS = 'INVENTORY_REPORT_OBJS_PUBS'
// For BRW customers who report for one or more of their Conbrw contracts
export const INVENTORY_REPORT_OBJS_CONTRACT_BREWERS = 'INVENTORY_REPORT_OBJS_CONTRACT_BREWERS'
// For CONBRW customers who report for one or more of their contractees' Conbrw contracts
export const INVENTORY_REPORT_OBJS_CONTRACTEES = 'INVENTORY_REPORT_OBJS_CONTRACTEES'

export const INFO_JUST_FOR_THE_WEB_APP = 'INFO_JUST_FOR_THE_WEB_APP'
export const INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME = 'INFO_JUST_FOR_THE_WEB_APP_DISPLAYED_CUSTOMER_NAME'
export const INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL = 'INFO_JUST_FOR_THE_WEB_APP_CUSTOM_FORM_ROW_LABEL'
export const INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID = 'INFO_JUST_FOR_THE_WEB_APP_CUSTOMER_BUSINESS_UNIT_ID'
export const INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID = 'INFO_JUST_FOR_THE_WEB_APP_BREWER_CUSTOMER_ID'
export const INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID = 'INFO_JUST_FOR_THE_WEB_APP_CONTRACT_BREWER_CUSTOMER_ID'
export const REPORT_CONSTELLATION_ARBITRARY_CONTENT = 'If you have zero pallets of a certain type, enter 0, don\'t leave the field blank (blank means you don\'t have a count).'
